import React from 'react';
import classNames from 'classnames';

import css from './Icon.module.scss';

export type IconType =
  | 'whatsapp'
  | 'facebook'
  | 'email'
  | 'n12-logo'
  | 'arrow-left'
  | 'n12-logo-black'
  | 'copy'
  | '7-10-logo-with-text'
  | '7-10-logo-with-text-animated'
  | '7-10-logo-small'
  | '7-10-logo-no-text';

interface Props {
  type: IconType;
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  secondaryColor?: string;
}

const Icon = ({ type, className, width, height, color = '#000', secondaryColor, ...iconProps }: Props) => {
  let icon = null;
  const svgProps = {
    width,
    height,
    viewBox: `0 0 ${width || 38} ${height || 38}`,
    xmlns: 'http://www.w3.org/2000/svg',
  };

  switch (type) {
    case 'whatsapp':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_771_4100)">
            <path
              d="M19.0508 4.91C17.1808 3.03 14.6908 2 12.0408 2C6.58078 2 2.13078 6.45 2.13078 11.91C2.13078 13.66 2.59078 15.36 3.45078 16.86L2.05078 22L7.30078 20.62C8.75078 21.41 10.3808 21.83 12.0408 21.83C17.5008 21.83 21.9508 17.38 21.9508 11.92C21.9508 9.27 20.9208 6.78 19.0508 4.91ZM12.0408 20.15C10.5608 20.15 9.11078 19.75 7.84078 19L7.54078 18.82L4.42078 19.64L5.25078 16.6L5.05078 16.29C4.23078 14.98 3.79078 13.46 3.79078 11.91C3.79078 7.37 7.49078 3.67 12.0308 3.67C14.2308 3.67 16.3008 4.53 17.8508 6.09C19.4108 7.65 20.2608 9.72 20.2608 11.92C20.2808 16.46 16.5808 20.15 12.0408 20.15ZM16.5608 13.99C16.3108 13.87 15.0908 13.27 14.8708 13.18C14.6408 13.1 14.4808 13.06 14.3108 13.3C14.1408 13.55 13.6708 14.11 13.5308 14.27C13.3908 14.44 13.2408 14.46 12.9908 14.33C12.7408 14.21 11.9408 13.94 11.0008 13.1C10.2608 12.44 9.77078 11.63 9.62078 11.38C9.48078 11.13 9.60078 11 9.73078 10.87C9.84078 10.76 9.98078 10.58 10.1008 10.44C10.2208 10.3 10.2708 10.19 10.3508 10.03C10.4308 9.86 10.3908 9.72 10.3308 9.6C10.2708 9.48 9.77078 8.26 9.57078 7.76C9.37078 7.28 9.16078 7.34 9.01078 7.33C8.86078 7.33 8.70078 7.33 8.53078 7.33C8.36078 7.33 8.10078 7.39 7.87078 7.64C7.65078 7.89 7.01078 8.49 7.01078 9.71C7.01078 10.93 7.90078 12.11 8.02078 12.27C8.14078 12.44 9.77078 14.94 12.2508 16.01C12.8408 16.27 13.3008 16.42 13.6608 16.53C14.2508 16.72 14.7908 16.69 15.2208 16.63C15.7008 16.56 16.6908 16.03 16.8908 15.45C17.1008 14.87 17.1008 14.38 17.0308 14.27C16.9608 14.16 16.8108 14.11 16.5608 13.99Z"
              fill="#EEF1F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_771_4100">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'facebook':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_771_4097)">
            <path
              d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z"
              fill="#EEF1F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_771_4097">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'email':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_771_4107)">
            <path
              d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z"
              fill="#EEF1F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_771_4107">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'n12-logo':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="74" height="33" viewBox="0 0 74 33" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6653 33H21.5516C20.0582 33 18.6891 32.1759 17.9978 30.8603L6.3131 8.68164V33H0V0.000155219H6.42681C7.91866 0.000155219 9.28923 0.822754 9.98058 2.13681L21.6653 24.3155V0.000155219H27.9768V33H21.6653ZM42.4921 0.000155219H37.0462L35.2451 3.47485H41.7295V29.9059H39.4114V5.72988H34.0747L32.4873 8.79552H36.0168V33H45.1211V2.52938C45.118 1.85512 44.8391 1.21083 44.3463 0.735849C43.8536 0.26087 43.1865 -0.0028415 42.4921 0.000155219ZM72.5855 29.9389H53.02L63.3872 14.4174C63.3948 14.4069 63.3993 14.4024 63.4054 14.3949C63.9542 13.6592 64.3378 12.8111 64.5258 11.9106L64.5334 11.8702C64.591 11.5525 64.6198 11.2289 64.6198 10.9052C64.6259 9.42033 64.0316 7.99689 62.9748 6.96453C60.7598 4.81438 57.2591 4.81438 55.044 6.96453C53.9873 7.99689 53.393 9.42033 53.399 10.9052H56.7785C56.7739 9.95525 57.3667 9.10568 58.2536 8.78953C59.1421 8.47337 60.1306 8.76106 60.7158 9.50574C61.301 10.2504 61.3556 11.2873 60.8507 12.0889C60.8401 12.1069 46.9268 33 46.9268 33H74L72.5855 29.9389ZM47.8288 11.0386H51.2158C51.2098 8.9978 52.0179 7.04094 53.4597 5.60851C56.4586 2.61629 61.2889 2.61629 64.2893 5.60851C66.85 8.15872 67.2685 12.1758 65.2899 15.2055L57.0756 27.6479H71.2922L69.8155 24.4594H63.084C63.084 24.4594 66.6878 19.2571 67.9462 17.1534C69.2045 15.0512 69.764 13.1333 69.8095 11.0236C69.817 10.2924 69.7443 9.56268 69.5942 8.84796C68.4586 3.28755 63.3372 -0.509287 57.7321 0.0555945C52.127 0.620476 47.8501 5.36278 47.8288 11.0386Z"
            fill="#EEF1F7"
          />
        </svg>
      );
      break;

    case 'n12-logo-black':
      icon = (
        <svg width="56" height="25" viewBox="0 0 56 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_954_9339)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.395 24.9999H16.309C15.1789 24.9999 14.1428 24.3756 13.6197 23.3789L4.77738 6.57682V24.9999H0V-9.60324e-05H4.86343C5.99238 -9.60324e-05 7.02954 0.623088 7.55271 1.61859L16.395 18.4207V-9.60324e-05H21.1712V24.9999H16.395ZM32.1555 -9.60324e-05H28.0344L26.6714 2.63226H31.5784V22.6559H29.8242V4.34062H25.7857L24.5845 6.66309H27.2554V24.9999H34.145V1.916C34.1427 1.40519 33.9316 0.917085 33.5587 0.55725C33.1858 0.197416 32.681 -0.00236628 32.1555 -9.60324e-05ZM54.9284 22.6808H40.1224L47.9677 10.9221C47.9734 10.9141 47.9769 10.9107 47.9815 10.9051C48.3968 10.3477 48.6871 9.70522 48.8293 9.02301L48.8351 8.99236C48.8787 8.75172 48.9005 8.50653 48.9005 8.26134C48.9051 7.13644 48.4553 6.05807 47.6556 5.27597C45.9794 3.64706 43.3303 3.64706 41.6541 5.27597C40.8544 6.05807 40.4047 7.13644 40.4093 8.26134H42.9666C42.9632 7.54168 43.4118 6.89806 44.0829 6.65855C44.7553 6.41904 45.5033 6.63698 45.9462 7.20114C46.389 7.7653 46.4303 8.5508 46.0483 9.15809C46.0402 9.17171 35.5114 24.9999 35.5114 24.9999H55.9989L54.9284 22.6808ZM36.1941 8.36237H38.7571C38.7525 6.81633 39.3641 5.33386 40.4552 4.24868C42.7245 1.98183 46.3798 1.98183 48.6504 4.24868C50.5882 6.18066 50.9048 9.22393 49.4076 11.5192L43.1915 20.9452H53.9498L52.8323 18.5297H47.7383C47.7383 18.5297 50.4654 14.5885 51.4177 12.9948C52.3699 11.4022 52.7933 9.94928 52.8277 8.35102C52.8334 7.79708 52.7784 7.24427 52.6648 6.70282C51.8054 2.49037 47.9298 -0.386039 43.6883 0.0419036C39.4467 0.469846 36.2101 4.06252 36.1941 8.36237Z"
              fill="#1A1D20"
            />
          </g>
          <defs>
            <clipPath id="clip0_954_9339">
              <rect width="56" height="25" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case 'arrow-left':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0 0 10 15" fill="none">
          <path
            d="M1.20026 6.79289C0.80974 7.18342 0.80974 7.81658 1.20026 8.20711L7.56422 14.5711C7.95475 14.9616 8.58791 14.9616 8.97844 14.5711C9.36896 14.1805 9.36896 13.5474 8.97844 13.1569L3.32158 7.5L8.97844 1.84315C9.36896 1.45262 9.36896 0.819457 8.97844 0.428933C8.58791 0.0384084 7.95475 0.0384083 7.56423 0.428933L1.20026 6.79289ZM1.90747 6.5L1.90737 6.5L1.90737 8.5L1.90747 8.5L1.90747 6.5Z"
            fill="#EEF1F7"
          />
        </svg>
      );
      break;

    case 'copy':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clipPath="url(#clip0_1002_1462)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 3C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V13C3 13.2652 3.10536 13.5196 3.29289 13.7071C3.48043 13.8946 3.73478 14 4 14H5C5.55228 14 6 14.4477 6 15C6 15.5523 5.55228 16 5 16H4C3.20435 16 2.44129 15.6839 1.87868 15.1213C1.31607 14.5587 1 13.7956 1 13V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H13C13.7956 1 14.5587 1.31607 15.1213 1.87868C15.6839 2.44129 16 3.20435 16 4V5C16 5.55228 15.5523 6 15 6C14.4477 6 14 5.55228 14 5V4C14 3.73478 13.8946 3.48043 13.7071 3.29289C13.5196 3.10536 13.2652 3 13 3H4ZM11 10C10.4477 10 10 10.4477 10 11V20C10 20.5523 10.4477 21 11 21H20C20.5523 21 21 20.5523 21 20V11C21 10.4477 20.5523 10 20 10H11ZM8 11C8 9.34315 9.34315 8 11 8H20C21.6569 8 23 9.34315 23 11V20C23 21.6569 21.6569 23 20 23H11C9.34315 23 8 21.6569 8 20V11Z"
              fill="#EEF1F7"
            />
          </g>
          <defs>
            <clipPath id="clip0_1002_1462">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;

    case '7-10-logo-small':
      icon = (
        <svg width="50" height="27" viewBox="0 0 50 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.3927 23.8455H8.77051V26.4654H11.3927V23.8455Z" fill="#EEF1F7" />
          <path d="M6.09401 10.4153L1.06152 26.5965H4.4528L9.30786 10.7823L6.09401 10.4153Z" fill="#EEF1F7" />
          <path d="M14.9722 11.4235V26.5966H17.2142V11.6775L14.9722 11.4235Z" fill="#EEF1F7" />
          <path
            d="M45.7887 15.0929C45.6516 16.0768 45.3935 17.0445 45.0145 17.9639C44.4298 19.3751 43.5669 20.6654 42.4862 21.7461C41.4055 22.8267 40.1151 23.6896 38.7037 24.2742C37.2924 24.8589 35.7681 25.1613 34.2398 25.1613C32.7115 25.1613 31.1873 24.8589 29.7759 24.2742C28.3646 23.6896 27.0742 22.8267 25.9935 21.7461C24.9128 20.6654 24.0499 19.3751 23.4651 17.9639C22.8804 16.5526 22.578 15.0284 22.578 13.5002C22.578 13.1535 22.5982 12.8107 22.6264 12.468L20.586 12.2382C20.5578 12.593 20.5416 12.9478 20.5376 13.3027C20.5376 15.0929 20.8925 16.8873 21.582 18.5445C22.2675 20.2017 23.2837 21.7219 24.5499 22.988C25.8201 24.2541 27.3363 25.2702 28.9936 25.9557C30.651 26.6452 32.4454 26.996 34.2358 27C36.0262 27 37.8206 26.6452 39.478 25.9557C41.1353 25.2702 42.6555 24.2541 43.9217 22.988C45.1879 21.7178 46.2041 20.2017 46.8896 18.5445C47.317 17.5123 47.6154 16.4276 47.7808 15.3228L45.7807 15.097L45.7887 15.0929Z"
            fill="#EEF1F7"
          />
          <path
            d="M14.2627 0.297485H0V3.68452L9.95609 3.59178L7.70599 10.8175L10.9198 11.1844L14.2627 0.297485Z"
            fill="#EEF1F7"
          />
          <path d="M18.8272 0.297485H16.5852V11.8255L18.8272 12.0795V0.297485Z" fill="#EEF1F7" />
          <path
            d="M25.0783 9.43935C25.663 8.02808 26.5259 6.73778 27.6066 5.65716C28.6873 4.57653 29.9777 3.71364 31.389 3.12898C32.8004 2.54431 34.3246 2.24189 35.8529 2.24189C37.3812 2.24189 38.9055 2.54431 40.3168 3.12898C41.7282 3.71364 43.0186 4.57653 44.0993 5.65716C45.18 6.73778 46.0429 8.02808 46.6276 9.43935C47.2123 10.8506 47.5147 12.3748 47.5147 13.903C47.5147 14.4352 47.4744 14.9675 47.4018 15.4957L49.4019 15.7215C49.5027 15.0521 49.5511 14.3788 49.5552 13.7014C49.5552 11.9111 49.2003 10.1168 48.5108 8.45952C47.8252 6.8023 46.8091 5.28216 45.5429 4.01606C44.2727 2.74995 42.7565 1.73384 41.0991 1.04837C39.4418 0.354832 37.6433 0 35.8529 0C34.0625 0 32.2681 0.354832 30.6108 1.04434C28.9534 1.72981 27.4332 2.74592 26.167 4.01202C24.9008 5.28216 23.8847 6.79826 23.1991 8.45549C22.6467 9.78208 22.316 11.2014 22.2031 12.6328L24.2435 12.8627C24.3484 11.6853 24.6307 10.524 25.0823 9.43531L25.0783 9.43935Z"
            fill="#EEF1F7"
          />
          <path d="M34.9209 15.9228V5.73749H34.2475V15.9228H34.9209Z" fill="#EEF1F7" />
          <path d="M33.0303 13.6684L33.4134 13.1119L40.6153 18.0473L40.2362 18.6038L33.0303 13.6684Z" fill="#EEF1F7" />
        </svg>
      );
      break;

    case '7-10-logo-with-text':
      icon = (
        <svg width="216" height="172" viewBox="0 0 216 172" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M50.4939 102.173H39.2583V113.399H50.4939V102.173Z" fill="#E0122B" />
          <path d="M27.7903 44.6273L6.22729 113.96H20.7582L41.561 46.1995L27.7903 44.6273Z" fill="#E0122B" />
          <path d="M65.8313 48.9468V113.96H75.4379V50.0353L65.8313 48.9468Z" fill="#EEF1F7" />
          <path
            d="M197.873 64.6699C197.285 68.8855 196.179 73.032 194.555 76.9712C192.05 83.0181 188.352 88.5468 183.722 93.177C179.091 97.8072 173.562 101.505 167.515 104.01C161.468 106.515 154.937 107.811 148.388 107.811C141.84 107.811 135.309 106.515 129.261 104.01C123.214 101.505 117.685 97.8072 113.054 93.177C108.424 88.5468 104.726 83.0181 102.221 76.9712C99.7158 70.9242 98.42 64.3935 98.42 57.8455C98.42 56.3597 98.5064 54.8912 98.6273 53.4226L89.8846 52.4378C89.7636 53.9582 89.6945 55.4786 89.6772 56.9989C89.6772 64.6699 91.1977 72.3582 94.1523 79.4591C97.0895 86.5599 101.444 93.0733 106.869 98.4983C112.312 103.923 118.808 108.277 125.909 111.214C133.011 114.169 140.699 115.672 148.371 115.689C156.042 115.689 163.731 114.169 170.832 111.214C177.934 108.277 184.448 103.923 189.873 98.4983C195.298 93.056 199.652 86.5599 202.59 79.4591C204.421 75.0361 205.7 70.3886 206.408 65.6547L197.838 64.6872L197.873 64.6699Z"
            fill="#EEF1F7"
          />
          <path
            d="M62.7916 1.27474H1.67896V15.7874L44.3386 15.3901L34.6974 46.3504L48.468 47.9227L62.7916 1.27474Z"
            fill="#E0122B"
          />
          <path d="M82.3495 1.27474H72.7429V50.6697L82.3495 51.7581V1.27474Z" fill="#EEF1F7" />
          <path
            d="M109.134 40.4455C111.639 34.3985 115.337 28.8699 119.967 24.2396C124.598 19.6094 130.127 15.9121 136.174 13.407C142.221 10.9018 148.752 9.60601 155.301 9.60601C161.849 9.60601 168.38 10.9018 174.428 13.407C180.475 15.9121 186.004 19.6094 190.634 24.2396C195.265 28.8699 198.963 34.3985 201.468 40.4455C203.973 46.4924 205.269 53.0231 205.269 59.5711C205.269 61.8517 205.096 64.1322 204.785 66.3955L213.355 67.363C213.787 64.4951 213.994 61.6098 214.012 58.7073C214.012 51.0363 212.491 43.348 209.537 36.2472C206.599 29.1463 202.245 22.6329 196.82 17.2079C191.377 11.7829 184.881 7.42911 177.78 4.49202C170.678 1.52038 162.972 0 155.301 0C147.629 0 139.941 1.52038 132.839 4.47474C125.738 7.41183 119.224 11.7656 113.799 17.1906C108.373 22.6329 104.019 29.129 101.082 36.2299C98.715 41.914 97.2982 47.9955 96.8145 54.1288L105.557 55.1136C106.006 50.0688 107.216 45.093 109.151 40.4282L109.134 40.4455Z"
            fill="#EEF1F7"
          />
          <path d="M151.307 68.2256V24.5839H148.421V68.2256H151.307Z" fill="#EEF1F7" />
          <path d="M143.207 58.566L144.848 56.1818L175.707 77.3288L174.083 79.7131L143.207 58.566Z" fill="#EEF1F7" />
          <rect x="0.988525" y="131.936" width="214.023" height="40.0634" fill="#E0122B" />
          <path
            d="M20.7977 162.016C19.5481 162.016 18.4673 161.814 17.8594 161.611L17.8931 158.132C18.4335 158.234 18.7375 158.268 19.4805 158.268C20.8653 158.268 21.3719 157.389 21.3719 155.431V142.934C23.4659 142.529 25.4248 142.09 28.4644 142.09C33.6656 142.09 37.1781 144.049 37.1781 149.419V161.645H32.6862V149.419C32.6862 146.953 31.5379 145.771 28.4306 145.771C27.5525 145.771 26.6744 145.839 25.8638 145.94V155.87C25.8638 159.754 24.3778 162.016 20.7977 162.016Z"
            fill="#F1F3F8"
          />
          <path d="M39.7629 161.645V142.461H44.2548V161.645H39.7629Z" fill="#F1F3F8" />
          <path
            d="M57.9151 142.461H62.407V152.526C62.407 156.072 61.7316 158.2 60.2117 159.652C58.8608 160.936 56.5979 161.645 54.335 161.645H46.6008V157.964H50.451L46.2631 142.461H50.8226L54.9768 157.862C55.8211 157.727 56.4628 157.356 56.9019 156.883C57.6787 156.038 57.9151 154.688 57.9151 151.276V142.461Z"
            fill="#F1F3F8"
          />
          <path
            d="M80.5928 142.461H85.0509V151.276C85.0509 158.436 81.2345 162.016 74.7836 162.016C68.3666 162.016 64.6177 158.504 64.6177 151.31V142.461H69.0758V152.154C71.6764 151.918 72.7234 150.77 72.7234 147.798V142.461H77.1816V147.392C77.1816 152.458 75.1551 154.856 69.7513 155.397C70.5957 157.288 72.2506 158.436 74.7836 158.436C78.8365 158.436 80.5928 155.903 80.5928 151.614V142.461Z"
            fill="#F1F3F8"
          />
          <path
            d="M92.3883 161.645V158.977L95.1578 156.545C99.2445 152.864 101.879 150.331 101.879 147.831C101.879 146.312 100.832 144.961 98.4339 144.961C96.6776 144.961 95.1578 145.737 93.4353 146.717V143.069C94.3135 142.394 96.8127 141.448 99.3796 141.448C103.736 141.448 106.371 144.049 106.371 147.359C106.371 151.074 103.128 153.641 100.494 156.174L98.6028 157.997V158.031H106.81V161.645H92.3883Z"
            fill="#F1F3F8"
          />
          <path
            d="M117.126 161.645V156.849H107.939V154.012L115.977 141.786H121.381V153.573H124.117V156.849H121.381V161.645H117.126ZM117.126 153.573V148.777C117.126 147.595 117.193 146.447 117.261 145.197H117.159C116.518 146.345 116.011 147.325 115.234 148.676L112.195 153.505L112.161 153.573H117.126Z"
            fill="#F1F3F8"
          />
          <path d="M198.142 142.254H133.159V149.719H198.142V142.254Z" fill="#F1F3F8" />
          <path d="M198.142 153.864H133.159V161.329H198.142V153.864Z" fill="#F1F3F8" />
        </svg>
      );
      break;

    case '7-10-logo-with-text-animated':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="252" height="202" viewBox="0 0 252 202" fill="none">
          <style>
            {`
              @keyframes Minute_t { 0% { transform: translate(175.38px,71.9763px) rotate(0deg) translate(-175.38px,-71.9763px); } 2.8571% { transform: translate(175.38px,71.9763px) rotate(0deg) translate(-175.38px,-71.9763px); } 57.1428% { transform: translate(175.38px,71.9763px) rotate(1800deg) translate(-175.38px,-71.9763px); } 100% { transform: translate(175.38px,71.9763px) rotate(1800deg) translate(-175.38px,-71.9763px); } }
              @keyframes Hours_t { 0% { transform: translate(175.392px,72.2157px) rotate(0deg) translate(-175.392px,-72.2157px); } 2.8571% { transform: translate(175.392px,72.2157px) rotate(0deg) translate(-175.392px,-72.2157px); } 57.1428% { transform: translate(175.392px,72.2157px) rotate(360deg) translate(-175.392px,-72.2157px); } 100% { transform: translate(175.392px,72.2157px) rotate(360deg) translate(-175.392px,-72.2157px); } }
              @keyframes TOP_t { 0% { transform: translate(-7.22px,0px); animation-timing-function: cubic-bezier(0.38025,0,0.97,0.545); } 2.2857% { transform: translate(-1.671px,0px); animation-timing-function: cubic-bezier(0.38025,0,0.97,0.545); } 2.8571% { transform: translate(0.93px,0px); } 78.5714% { transform: translate(0.93px,0px); animation-timing-function: cubic-bezier(0,0,1,1); } 85.7142% { transform: translate(-7.22px,0px); } 100% { transform: translate(-7.22px,0px); } }
           `}
          </style>
          <g transform="translate(126.213,127.205) translate(-125.676,-74.7949)">
            <path
              d="M58.6768,119.993L45.4814,119.993L45.4814,133.177L58.6768,133.177L58.6768,119.993Z"
              fill="#e0122b"
              fillOpacity="1"
              transform="translate(51.5422,74.1746) translate(-52.0791,-126.585)"
            />
            <path
              d="M32.013,52.4104L6.68896,133.836L23.7543,133.836L48.1855,54.2568L32.013,52.4104Z"
              fill="#e0122b"
              fillOpacity="1"
              transform="translate(26.9004,40.7128) translate(-27.4372,-93.1232)"
            />
            <path
              d="M76.6895,57.4838L76.6895,133.837L87.9716,133.837L87.9716,58.7621L76.6895,57.4838Z"
              fill="#EEF1F7"
              fillOpacity="0.9"
              transform="translate(81.7937,43.25) translate(-82.3306,-95.6604)"
            />
            <path
              d="M231.761,75.9496C231.071,80.9005,229.772,85.7702,227.865,90.3964C224.923,97.498,220.58,103.991,215.142,109.429C209.704,114.867,203.211,119.209,196.108,122.151C189.006,125.093,181.336,126.615,173.646,126.615C165.955,126.615,158.285,125.093,151.183,122.151C144.081,119.209,137.587,114.867,132.149,109.429C126.711,103.991,122.368,97.498,119.426,90.3964C116.484,83.2947,114.962,75.6249,114.962,67.9349C114.962,66.1899,115.063,64.4652,115.205,62.7405L104.938,61.584C104.796,63.3695,104.715,65.1551,104.694,66.9406C104.694,75.9496,106.48,84.9788,109.95,93.3182C113.399,101.658,118.513,109.307,124.885,115.678C131.276,122.049,138.906,127.163,147.246,130.612C155.586,134.082,164.616,135.847,173.625,135.867C182.635,135.867,191.665,134.082,200.004,130.612C208.344,127.163,215.994,122.049,222.366,115.678C228.738,109.287,233.851,101.658,237.301,93.3182C239.452,88.1238,240.953,82.6657,241.785,77.1061L231.72,75.9699L231.761,75.9496Z"
              fill="#EEF1F7"
              fillOpacity="0.9"
              transform="translate(172.703,46.3151) translate(-173.24,-98.7255)"
            />
            <rect
              width="251.352"
              height="47.0512"
              fill="#E0122B"
              transform="translate(125.676,126.064) translate(-125.676,-23.5256)"
            />
            <path
              d="M23.8002,190.275C22.3326,190.275,21.0633,190.037,20.3494,189.799L20.389,185.714C21.0237,185.833,21.3807,185.873,22.2533,185.873C23.8795,185.873,24.4745,184.841,24.4745,182.541L24.4745,167.865C26.9337,167.389,29.2343,166.873,32.8041,166.873C38.9125,166.873,43.0376,169.174,43.0376,175.48L43.0376,189.839L37.7622,189.839L37.7622,175.48C37.7622,172.585,36.4136,171.197,32.7645,171.197C31.7332,171.197,30.7019,171.276,29.7499,171.395L29.7499,183.056C29.7499,187.618,28.0047,190.275,23.8002,190.275Z"
              fill="#EEF1F7"
              transform="translate(31.1566,126.164) translate(-31.6935,-178.574)"
            />
            <path
              d="M46.0732,189.839L46.0732,167.309L51.3487,167.309L51.3487,189.839L46.0732,189.839Z"
              fill="#EEF1F7"
              transform="translate(48.1741,126.164) translate(-48.7109,-178.574)"
            />
            <path
              d="M67.3915,167.309L72.6669,167.309L72.6669,179.13C72.6669,183.294,71.8737,185.793,70.0887,187.499C68.5021,189.006,65.8446,189.839,63.1871,189.839L54.1038,189.839L54.1038,185.516L58.6256,185.516L53.7072,167.309L59.0619,167.309L63.9407,185.397C64.9323,185.238,65.6859,184.802,66.2016,184.246C67.1139,183.255,67.3915,181.668,67.3915,177.662L67.3915,167.309Z"
              fill="#EEF1F7"
              transform="translate(62.6502,126.164) translate(-63.187,-178.574)"
            />
            <path
              d="M94.0246,167.309L99.2603,167.309L99.2603,177.662C99.2603,186.071,94.7782,190.275,87.2022,190.275C79.6659,190.275,75.2631,186.15,75.2631,177.702L75.2631,167.309L80.4989,167.309L80.4989,178.693C83.5531,178.416,84.7827,177.067,84.7827,173.576L84.7827,167.309L90.0184,167.309L90.0184,173.1C90.0184,179.05,87.6386,181.866,81.2922,182.501C82.2838,184.722,84.2274,186.071,87.2022,186.071C91.962,186.071,94.0246,183.096,94.0246,178.059L94.0246,167.309Z"
              fill="#EEF1F7"
              transform="translate(86.7248,126.382) translate(-87.2617,-178.792)"
            />
            <path
              d="M107.878,189.839L107.878,186.705L111.13,183.85C115.929,179.526,119.023,176.551,119.023,173.616C119.023,171.831,117.794,170.245,114.978,170.245C112.915,170.245,111.13,171.157,109.107,172.307L109.107,168.023C110.138,167.23,113.074,166.119,116.088,166.119C121.205,166.119,124.299,169.174,124.299,173.061C124.299,177.424,120.491,180.438,117.397,183.413L115.176,185.555L115.176,185.595L124.814,185.595L124.814,189.839L107.878,189.839Z"
              fill="#EEF1F7"
              transform="translate(115.809,125.569) translate(-116.346,-177.979)"
            />
            <path
              d="M136.93,189.839L136.93,184.207L126.141,184.207L126.141,180.875L135.581,166.516L141.927,166.516L141.927,180.359L145.14,180.359L145.14,184.207L141.927,184.207L141.927,189.839L136.93,189.839ZM136.93,180.359L136.93,174.727C136.93,173.338,137.009,171.99,137.088,170.522L136.969,170.522C136.216,171.871,135.621,173.021,134.708,174.608L131.138,180.28L131.099,180.359L136.93,180.359Z"
              fill="#EEF1F7"
              transform="translate(135.104,125.767) translate(-135.641,-178.178)"
            />
            <path
              d="M232.077,167.064L155.76,167.064L155.76,175.832L232.077,175.832L232.077,167.064Z"
              fill="#EEF1F7"
              transform="translate(193.382,119.038) translate(-193.918,-171.448)"
            />
            <path
              d="M232.077,180.7L155.76,180.7L155.76,189.468L232.077,189.468L232.077,180.7Z"
              fill="#EEF1F7"
              transform="translate(193.382,132.674) translate(-193.918,-185.084)"
            />
          </g>
          <path
            id="Minute"
            d="M177.074,80.126L177.074,28.8724L173.685,28.8724L173.685,80.126L177.074,80.126Z"
            fill="#EEF1F7"
            fillOpacity="0.9"
            transform="translate(175.38,71.9763) translate(-175.38,-71.9763)"
            style={{ animation: '14s linear infinite both Minute_t' }}
          />
          <path
            id="Hours"
            d="M167.561,68.7814L169.489,65.9813L205.73,90.8168L203.823,93.6168L167.561,68.7814Z"
            fill="#EEF1F7"
            fillOpacity="0.9"
            transform="translate(175.392,72.2157) translate(-175.392,-72.2157)"
            style={{ animation: '14s linear infinite both Hours_t' }}
          />
          <g
            id="TOP"
            transform="translate(118.5,39.5562) translate(-125.72,-39.5562)"
            style={{ animation: '14s linear infinite both TOP_t' }}
          >
            <path
              d="M73.1197,1.49739L1.3479,1.49739L1.3479,18.5413L51.4481,18.0747L40.1253,54.4351L56.2978,56.2815L73.1197,1.49739Z"
              fill="#e0122b"
              fillOpacity="1"
              transform="translate(35.8859,28.8894) translate(-37.2338,-28.8894)"
            />
            <path
              d="M96.0883,1.49739L84.8062,1.49739L84.8062,59.5077L96.0883,60.786L96.0883,1.49739Z"
              fill="#EEF1F7"
              fillOpacity="0.9"
              transform="translate(89.0994,31.1417) translate(-90.4472,-31.1417)"
            />
            <path
              d="M127.545,47.4999C130.487,40.3982,134.83,33.9053,140.268,28.4675C145.706,23.0296,152.199,18.6875,159.301,15.7454C166.403,12.8033,174.074,11.2815,181.764,11.2815C189.455,11.2815,197.125,12.8033,204.227,15.7454C211.329,18.6875,217.823,23.0296,223.261,28.4675C228.699,33.9053,233.041,40.3982,235.984,47.4999C238.926,54.6015,240.448,62.2713,240.448,69.9614C240.448,72.6397,240.245,75.3181,239.88,77.9761L249.944,79.1124C250.452,75.7442,250.695,72.3557,250.716,68.9469C250.716,59.9379,248.93,50.9087,245.46,42.5693C242.01,34.2299,236.897,26.5805,230.525,20.2093C224.133,13.8381,216.504,8.72488,208.164,5.27551C199.824,1.78556,190.774,0,181.764,0C172.755,0,163.725,1.78556,155.385,5.25522C147.045,8.70459,139.395,13.8178,133.024,20.189C126.652,26.5805,121.539,34.2097,118.089,42.549C115.309,49.2246,113.645,56.3668,113.077,63.5699L123.345,64.7265C123.872,58.8017,125.293,52.958,127.565,47.4796L127.545,47.4999Z"
              fill="#EEF1F7"
              fillOpacity="0.9"
              transform="translate(180.549,39.5562) translate(-181.897,-39.5562)"
            />
          </g>
        </svg>
      );
      break;

    case '7-10-logo-no-text':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="103" height="56" viewBox="0 0 103 56" fill="none">
          <path d="M23.7385 49.4573H18.2998V54.8913H23.7385V49.4573Z" fill="#F31229" />
          <path d="M12.7488 21.6018L2.31104 55.1627H9.3448L19.4145 22.3628L12.7488 21.6018Z" fill="#F31229" />
          <path d="M31.1628 23.693V55.1631H35.813V24.2198L31.1628 23.693Z" fill="#EEF1F7" />
          <path
            d="M95.0785 31.3039C94.7941 33.3445 94.2589 35.3517 93.4727 37.2584C92.26 40.1855 90.4702 42.8617 88.2287 45.103C85.9873 47.3443 83.311 49.134 80.3837 50.3466C77.4565 51.5592 74.295 52.1865 71.1252 52.1865C67.9554 52.1865 64.794 51.5592 61.8668 50.3466C58.9395 49.134 56.2632 47.3443 54.0217 45.103C51.7803 42.8617 49.9905 40.1855 48.7778 37.2584C47.565 34.3314 46.9378 31.1701 46.9378 28.0005C46.9378 27.2813 46.9796 26.5705 47.0381 25.8596L42.8062 25.3829C42.7476 26.1189 42.7142 26.8548 42.7058 27.5907C42.7058 31.3039 43.4418 35.0255 44.872 38.4627C46.2938 41.8999 48.4014 45.0528 51.0276 47.6788C53.6621 50.3048 56.8068 52.4123 60.2442 53.834C63.6817 55.2641 67.4034 55.9916 71.1169 56C74.8303 56 78.5521 55.2641 81.9895 53.834C85.4269 52.4123 88.58 50.3048 91.2062 47.6788C93.8323 45.0444 95.9399 41.8999 97.3618 38.4627C98.2483 36.3218 98.8672 34.0721 99.2101 31.7806L95.0618 31.3123L95.0785 31.3039Z"
            fill="#EEF1F7"
          />
          <path
            d="M29.6913 0.617188H0.109375V7.64215L20.759 7.44979L16.0922 22.4364L22.7579 23.1974L29.6913 0.617188Z"
            fill="#F31229"
          />
          <path d="M39.1587 0.617188H34.5085V24.5271L39.1587 25.054V0.617188Z" fill="#EEF1F7" />
          <path
            d="M52.1239 19.5779C53.3366 16.6508 55.1264 13.9746 57.3678 11.7334C59.6093 9.49206 62.2856 7.70236 65.2129 6.48972C68.1401 5.27708 71.3015 4.64985 74.4713 4.64985C77.6411 4.64985 80.8026 5.27708 83.7298 6.48972C86.6571 7.70236 89.3334 9.49206 91.5748 11.7334C93.8163 13.9746 95.6061 16.6508 96.8188 19.5779C98.0315 22.505 98.6588 25.6662 98.6588 28.8358C98.6588 29.9397 98.5752 31.0436 98.4246 32.1392L102.573 32.6075C102.782 31.2192 102.882 29.8226 102.891 28.4176C102.891 24.7044 102.155 20.9829 100.725 17.5457C99.3028 14.1085 97.1952 10.9556 94.569 8.32959C91.9345 5.7036 88.7898 3.59611 85.3523 2.17439C81.9149 0.735948 78.1848 0 74.4713 0C70.7579 0 67.0361 0.735948 63.5987 2.16603C60.1613 3.58775 57.0082 5.69523 54.382 8.32123C51.7559 10.9556 49.6483 14.1001 48.2264 17.5373C47.0806 20.2887 46.3948 23.2325 46.1606 26.2014L50.3926 26.6781C50.6101 24.2361 51.1955 21.8275 52.1322 19.5695L52.1239 19.5779Z"
            fill="#EEF1F7"
          />
          <path d="M72.5378 33.0253V11.9002H71.1411V33.0253H72.5378Z" fill="#EEF1F7" />
          <path d="M68.6172 28.3494L69.4117 27.1953L84.3491 37.4317L83.5629 38.5858L68.6172 28.3494Z" fill="#EEF1F7" />
        </svg>
      );
      break;

    default:
      break;
  }

  return (
    <i className={classNames(css.icon, className)} {...iconProps}>
      {icon}
    </i>
  );
};

Icon.defaultProps = {
  className: '',
  width: 38.945,
  height: 38.948,
  color: '#111',
  secondaryColor: '',
};

export default Icon;
