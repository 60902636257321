import React, { FC } from 'react';
import cn from 'classnames';

import { analytics } from 'src/utils';
import Icon from 'src/components/common/Icon';
import { share, ShareType } from 'src/utils/share';

import css from './Shares.module.scss';

// const HE_SOCIALS = {
//   facebook: 'פייסבוק',
//   whatsApp: 'ווטסאפ',
//   twitter: 'טוויטר אקס',
//   email: '',
// };

interface Props {
  className?: string;
}

const Shares: FC<Props> = ({ className = '' }) => {
  const onShareBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { type } = e.currentTarget.dataset;

    analytics.gtag.event('interface sharing', {
      CUSTOM_PARAMETER: `${type as ShareType}`,
    });
    share(type as ShareType);
  };

  const onCopyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        analytics.gtag.event('interface sharing', {
          CUSTOM_PARAMETER: 'copy',
        });
        console.info('URL copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy URL: ', err);
      });
  };

  return (
    <div className={cn(css.shares, className)}>
      {/* <button data-type="email" className={css.shareBtn} onClick={onShareBtnClick}>
        <Icon type="email" />
      </button> */}
      <button data-type="copy" className={css.shareBtn} onClick={onCopyClick}>
        <Icon type="copy" />
      </button>
      <button data-type="whatsApp" className={css.shareBtn} onClick={onShareBtnClick}>
        <Icon type="whatsapp" />
      </button>
      <button data-type="facebook" className={css.shareBtn} onClick={onShareBtnClick}>
        <Icon type="facebook" />
      </button>
    </div>
  );
};

export default Shares;
