import React from 'react';
import cn from 'classnames';

import { analytics } from 'src/utils';

import css from './ActionButton.module.scss';

const ActionButton = ({
  className,
  isDisabled,
  onClick,
}: {
  className?: string;
  isDisabled: boolean;
  onClick?: () => void;
}) => {
  const onButtonClick = () => {
    analytics.gtag.event('realtime_button');
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={cn(className, css.actionButton, isDisabled && css.disabled)} onClick={onButtonClick}>
      בשעה זאת ב-7.10
    </div>
  );
};

export default ActionButton;
