import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Timeline } from './actions';

export type TimelineState = Timeline;

const initialState: TimelineState = {
  data: {
    items: [],
    settings: {
      _id: 'general',
      isRealTimeMode: true,
    },
  },
};

export const config = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<Timeline>) => {
      return action.payload;
    },
  },
});

export const { init } = config.actions;
export default config.reducer;
