import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { useAppSelector } from 'src/hooks/store';
import { selectConfig } from 'src/store/config/selectors';
import { TIMELINE_ROUTE } from 'src/constants/routes';
import { analytics } from 'src/utils';
import { useResponsive } from 'src/hooks/responsive';
import Head from 'src/components/common/Head';
import Icon from 'src/components/common/Icon';
import Cooladata from 'src/components/common/Cooladata';

import css from './Main.module.scss';

const REDIRECT_COUNTDOWN = 10000;

const Main = () => {
  const { seo, og } = useAppSelector(selectConfig).seo_og;
  const [isMobile] = useResponsive(['MOBILE']);
  const [videoBgUrl, setVideoBgUrl] = React.useState<string | null>(null);
  const [isCountdownStarted, setIsCountdownStarted] = React.useState<boolean>(false);

  const nav = useNavigate();
  React.useEffect(() => {
    setIsCountdownStarted(true);
    const timeoutId = setTimeout(() => {
      analytics.gtag.event('entrance_button');
      nav(TIMELINE_ROUTE);
    }, REDIRECT_COUNTDOWN);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  React.useEffect(() => {
    if (typeof isMobile !== 'undefined') {
      if (isMobile) {
        setVideoBgUrl(
          'https://makostorepdl-a.akamaihd.net/SHORT/STORIES/public/2024/09/22/Hadashot_220924_0710_2/Hadashot_220924_0710_2_550.mp4',
        );
      } else {
        setVideoBgUrl(
          'https://makostorepdl-a.akamaihd.net/SHORT/TV/public/2024/Hadashot_220924_0710_1/Hadashot_220924_0710_1_3100.mp4',
        );
      }
    }
  }, [isMobile]);

  return (
    <>
      <Head seo={seo} og={og}></Head>
      {videoBgUrl && (
        <video muted autoPlay playsInline loop className={css.videoBg}>
          <source src={videoBgUrl} type="video/mp4" />
        </video>
      )}
      <div className={css.main}>
        {isCountdownStarted ? (
          <Icon type="7-10-logo-with-text-animated" className={css.mainLogoWithText} />
        ) : (
          <Icon type="7-10-logo-with-text" className={css.mainLogoWithText} />
        )}
        <h1 className={css.title}>
          אנחנו מביאים לכם את 24 השעות הראשונות של מתקפת הפתע הרצחנית של חמאס כפי ששודרו בחדשות 12 בזמן אמת.
        </h1>
        <p className={css.text}>
          מתוך כבוד לאלה שלחמו, לאלה שנפלו בזמן שהגנו על הבית, לאלה שנרצחו ולאלה שנלקחו בשבי. מתוך כבוד לחשיבות התיעוד
          ההיסטורי של היום הקשה, הדרמטי והמדמם בתולדות המדינה
        </p>
        <Link to={TIMELINE_ROUTE} className={css.link} onClick={() => analytics.gtag.event('entrance_button')}>
          {' '}
          כניסה &gt;&gt;
          <span className={cn(css.progressBg, isCountdownStarted && css.active)}></span>
        </Link>

        <div className={css.rabbiCredits}>
          Developed by{' '}
          <a href="https://www.rabbi.co.il/" target="_blank">
            Rabbi Interactive
          </a>
        </div>
      </div>
      <Cooladata pageId="HP" />
    </>
  );
};

export default () => {
  return (
    <>
      <Main />
    </>
  );
};
