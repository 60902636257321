import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Init } from './actions';

export type ConfigState = Init;

const initialState: ConfigState = {
  seo_og: {
    seo: {
      title: '',
      description: '',
    },
    og: {
      title: '',
      description: '',
    },
  },
};

export const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<Init>) => {
      return action.payload;
    },
  },
});

export const { init } = config.actions;
export default config.reducer;
