export const REDUX_STORAGE_KEY = '__REDUX_STORAGE';
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const SHARE_CONSTANTS = {
  TWITTER: 'Twitter',
  FACEBOOK: 'Facebook',
  MAIL: 'E-mail',
  WHATS_APP: 'whatsApp',
};

export const IMGIX_URL = ''; // TODO: change this

export const VIDEO_TOKEN = process.env.VIDEO_TOKEN;
export const TICKET_URL = process.env.TICKET_URL;
